/**
 * Utilities
 */

.mi-wrapper {
  /*position: relative;
   min-height: 100vh;*/
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  flex: 1 0 auto;

  @media #{$layout-laptop} {
    padding-left: 0;
  }

  @media #{$layout-notebook} {
    padding-left: 0;
  }

  @media #{$layout-tablet} {
    padding-left: 0;
  }

  @media #{$layout-mobile-lg} {
    padding-left: 0;
  }
}

.color-theme {
  color: $color-theme;
}

.mt-30-reverse {
  margin-top: -30px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-50-reverse {
  margin-top: -50px;
}

.mt-50 {
  margin-top: 50px;
}

.mi-padding-top {
  padding-top: 120px;

  @media #{$layout-tablet} {
    padding-top: 100px;
  }

  @media #{$layout-mobile-lg} {
    padding-top: 80px;
  }
}

.mi-padding-bottom {
  padding-bottom: 120px;

  @media #{$layout-tablet} {
    padding-bottom: 100px;
  }

  @media #{$layout-mobile-lg} {
    padding-bottom: 80px;
  }
}

.mi-section {
  position: relative;
}

.size-md[class^="lni-"],
.size-lg[class^="lni-"],
.size-sm[class^="lni-"],
.size-xs[class^="lni-"] {
  font-size: inherit !important;
}

.required {
  color: #f00;
}
