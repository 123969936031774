.mi-footer {
  &-container {
    background-color: #000;
    height: 300px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex: 1 0 auto;
  }
  &-container-list {
    align-items: center;
    display: flex;
    width: 100%;
    flex: 1 0 auto;
    &-col {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      align-items: center;
    }
  }
  &-container-list ul li a {
    transition: all 0.3s ease 0s;
    color: #777777;
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    font-size: 0.9rem;
    @media #{$layout-tablet} {
      font-size: 0.7rem;
    }

    @media #{$layout-mobile-lg} {
      font-size: 0.7rem;
    }
  }
  &-container-list ul li {
    color: #777777;
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    font-size: 0.9rem;
    @media #{$layout-tablet} {
      font-size: 0.7rem;
    }

    @media #{$layout-mobile-lg} {
      font-size: 0.7rem;
    }
  }
  /* Weird - take a look at*/
  &-container-list h4 {
    color: "white";
    margin-bottom: 20px;
    font-size: 1.25rem;
    width: 40%;
  }
  &-container-list ul {
    width: 40%;
    list-style: none;
  }
  &-container-list ul li a:hover {
    color: #fff;
  }
}
