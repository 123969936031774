@import url(https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,700,800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.mi-home-card-apple svg {
  fill: black;
}
.mi-home-card-apple:hover svg {
  fill: url("#Pattern");
}

html,
body,
#root {
  height: 100vh;
  -webkit-overflow-scrolling: touch;
}
.text-center {
  text-align: center;
}
.mi-button {
  text-decoration: none;
}

/**
 * Reset
 */
html {
  color: #222;
  font-size: 1em;
  line-height: 1.4; }

/*::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}*/
* {
  border: 0;
  box-sizing: border-box;
  color: inherit;
  font-size: inherit;
  margin: 0;
  padding: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

.hidden {
  display: none !important; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap; }

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
  white-space: inherit; }

.invisible {
  visibility: hidden; }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

@media only screen and (min-width: 35em) {
  /* Style adjustments for viewports that meet the condition */ }

@media print, (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 1.25dppx), (min-resolution: 120dpi) {
  /* Style adjustments for high resolution devices */ }

@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: ""; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }

/**
 * Typography
 */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

*::-webkit-scrollbar-track {
  /*border-radius: 10px;
  background-color: #383838;*/ }

*::-webkit-scrollbar {
  /*width: 8px;
  background-color: #383838;*/ }

*::-webkit-scrollbar-thumb {
  /*border-radius: 10px;
  background-color: #6b6b6b;*/ }

html {
  /*overflow: hidden;
  overflow-y: auto;*/ }

body {
  /*overflow: hidden !important;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: #8d9a9e;
  font-weight: 400;
  background: #ffffff; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    body {
      font-size: 16px; } }
  @media only screen and (max-width: 767px) {
    body {
      font-size: 15px; } }

img {
  max-width: 100%;
  display: block;
  margin: auto; }

::-webkit-input-placeholder {
  color: #8d9a9e; }

:-moz-placeholder {
  color: #8d9a9e; }

::-moz-placeholder {
  color: #8d9a9e; }

:-ms-input-placeholder {
  color: #8d9a9e; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: #000; }

h1 {
  font-size: 5rem;
  text-shadow: 0px 3px 6px #000;
  color: #fff; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    h1 {
      font-size: 2.9857rem; } }
  @media only screen and (max-width: 767px) {
    h1 {
      font-size: 2.6857rem; } }

h2 {
  font-size: 4.5rem; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    h2 {
      font-size: 2.37rem; } }
  @media only screen and (max-width: 767px) {
    h2 {
      font-size: 2.17rem; } }

h3 {
  font-size: 2rem; }
  @media only screen and (max-width: 767px) {
    h3 {
      font-size: 1.91rem; } }

h4 {
  font-size: 1.71rem;
  color: #fff; }
  @media only screen and (max-width: 767px) {
    h4 {
      font-size: 1.51rem; } }

h5 {
  font-size: 1.43rem; }
  @media only screen and (max-width: 767px) {
    h5 {
      font-size: 1.23rem; } }

h6 {
  font-size: 1.14rem; }
  @media only screen and (max-width: 767px) {
    h6 {
      font-size: 1.04rem; } }

/*::-moz-selection {
    background: $color-theme;
    color: #ffffff;
}

::-ms-selection {
    background: $color-theme;
    color: #ffffff;
}

::-o-selection {
    background: $color-theme;
    color: #ffffff;
}

::selection {
    background: $color-theme;
    color: #ffffff;
}*/
a:hover, a:focus, a:active {
  text-decoration: none;
  outline: none; }

a,
button {
  cursor: pointer; }

input,
button,
select,
textarea {
  background: transparent;
  /*border: 1px solid $color-border;*/
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: #8d9a9e; }
  input::-webkit-input-placeholder,
  button::-webkit-input-placeholder,
  select::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #8d9a9e; }
  input:-moz-placeholder,
  button:-moz-placeholder,
  select:-moz-placeholder,
  textarea:-moz-placeholder {
    color: #8d9a9e; }
  input::-moz-placeholder,
  button::-moz-placeholder,
  select::-moz-placeholder,
  textarea::-moz-placeholder {
    color: #8d9a9e; }
  input:-ms-input-placeholder,
  button:-ms-input-placeholder,
  select:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: #8d9a9e; }
  input:focus, input:active,
  button:focus,
  button:active,
  select:focus,
  select:active,
  textarea:focus,
  textarea:active {
    outline: none;
    border-color: #037fff; }

input,
select,
textarea {
  width: 100%;
  font-size: 14px;
  vertical-align: middle; }

input,
select,
button {
  height: 50px;
  padding: 0 15px; }
  @media only screen and (max-width: 767px) {
    input,
    select,
    button {
      height: 44px; } }

textarea {
  padding: 10px 15px; }

blockquote {
  border-left: 5px solid #037fff;
  font-size: 18px;
  line-height: 27px;
  padding: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  margin-left: 25px;
  background: white; }
  blockquote p {
    margin-bottom: 0;
    font-style: italic;
    font-size: 1.2rem; }
  blockquote footer {
    font-size: 14px; }
    blockquote footer a {
      color: #037fff; }
      blockquote footer a:hover {
        color: #037fff; }
  @media only screen and (max-width: 767px) {
    blockquote {
      font-size: 16px;
      line-height: 25px;
      padding: 20px;
      margin-left: 0; } }

[class^="flaticon-"],
[class*=" flaticon-"],
[class^="flaticon-"],
[class*=" flaticon-"] {
  line-height: 1; }
  [class^="flaticon-"]::before, [class^="flaticon-"]::after,
  [class*=" flaticon-"]::before,
  [class*=" flaticon-"]::after,
  [class^="flaticon-"]::before,
  [class^="flaticon-"]::after,
  [class*=" flaticon-"]::before,
  [class*=" flaticon-"]::after {
    margin-left: 0;
    font-size: inherit;
    vertical-align: middle; }

.stylish-list {
  padding-left: 0; }
  .stylish-list li {
    list-style: none;
    line-height: 30px;
    position: relative;
    padding-left: 35px; }
  .stylish-list i {
    position: absolute;
    left: 0;
    top: 7px;
    font-size: 16px;
    color: #037fff; }

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  -webkit-transition: background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s;
  transition: background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s; }

/* Checkbox & Radio Styles */
input[type="checkbox"],
input[type="radio"] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  display: none; }
  input[type="checkbox"] ~ label,
  input[type="radio"] ~ label {
    position: relative;
    padding-left: 24px;
    cursor: pointer; }
    input[type="checkbox"] ~ label:before,
    input[type="radio"] ~ label:before {
      content: "";
      font-family: "Font Awesome 5 free";
      font-weight: 700;
      position: absolute;
      left: 0;
      top: 5px;
      border: 1px solid #d4e0e6;
      height: 15px;
      width: 15px;
      line-height: 1;
      font-size: 13px; }
  input[type="checkbox"]:checked ~ label,
  input[type="radio"]:checked ~ label {
    color: #037fff; }
    input[type="checkbox"]:checked ~ label:before,
    input[type="radio"]:checked ~ label:before {
      content: "\f00c";
      color: #037fff;
      border-color: #037fff; }

input[type="radio"] ~ label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  border: 2px solid #d4e0e6;
  border-radius: 100px;
  height: 15px;
  width: 15px;
  margin-top: -8px;
  line-height: 0.5;
  font-size: 28px; }

input[type="radio"]:checked ~ label::before {
  content: "";
  color: #037fff;
  border-color: #037fff; }

/**
 * Utilities
 */
.mi-wrapper {
  /*position: relative;
   min-height: 100vh;*/
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  flex: 1 0 auto; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .mi-wrapper {
      padding-left: 0; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .mi-wrapper {
      padding-left: 0; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .mi-wrapper {
      padding-left: 0; } }
  @media only screen and (max-width: 767px) {
    .mi-wrapper {
      padding-left: 0; } }

.color-theme {
  color: #037fff; }

.mt-30-reverse {
  margin-top: -30px; }

.mt-30 {
  margin-top: 30px; }

.mt-50-reverse {
  margin-top: -50px; }

.mt-50 {
  margin-top: 50px; }

.mi-padding-top {
  padding-top: 120px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .mi-padding-top {
      padding-top: 100px; } }
  @media only screen and (max-width: 767px) {
    .mi-padding-top {
      padding-top: 80px; } }

.mi-padding-bottom {
  padding-bottom: 120px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .mi-padding-bottom {
      padding-bottom: 100px; } }
  @media only screen and (max-width: 767px) {
    .mi-padding-bottom {
      padding-bottom: 80px; } }

.mi-section {
  position: relative; }

.size-md[class^="lni-"],
.size-lg[class^="lni-"],
.size-sm[class^="lni-"],
.size-xs[class^="lni-"] {
  font-size: inherit !important; }

.required {
  color: #f00; }

/**
 * Button
 */
.mi-button {
  position: relative;
  padding: 0 30px;
  background: #cce5ff;
  color: #000;
  border: 0;
  display: inline-block;
  z-index: 1;
  text-transform: uppercase;
  font-size: 0.9rem;
  letter-spacing: 2px;
  height: 50px;
  line-height: 50px;
  transition: all 0.4s ease-out;
  align-items: center;
  display: flex;
  flex: 1 0 auto; }
  .mi-button::before {
    content: "";
    position: absolute;
    left: 0;
    top: auto;
    bottom: 0;
    height: 2px;
    width: 100%;
    background: #000;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: 0 0;
    transition: all 0.4s ease-out; }
  .mi-button:hover {
    color: #fff;
    background-color: #037fff; }
  .mi-button:hover::before {
    transform: scaleX(1); }
  @media only screen and (max-width: 767px) {
    .mi-button {
      height: 45px;
      line-height: 45px; } }

.mi-readmore {
  position: relative;
  color: #037fff;
  transition: all 0.4s ease-out; }
  .mi-readmore::after {
    content: "";
    position: absolute;
    left: 0;
    top: auto;
    bottom: 0;
    height: 1px;
    width: 0;
    background: #037fff;
    transition: all 0.4s ease-out; }
  .mi-readmore:hover {
    color: #037fff; }
    .mi-readmore:hover::after {
      width: 100%; }

/*
 * Pagination
 */
.mi-pagination ul {
  list-style: none;
  margin-top: -5px;
  margin-left: -5px; }

.mi-pagination li {
  display: inline-block;
  list-style: none;
  margin-top: 5px;
  margin-left: 5px; }
  .mi-pagination li a {
    display: inline-block;
    border: 1px solid #037fff;
    height: 40px;
    width: 40px;
    text-align: center;
    color: #037fff;
    line-height: 38px; }
  .mi-pagination li.is-active a {
    background: #037fff;
    color: #ffffff; }

/**
 * Header Styles
 */
.mi-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  z-index: 5000;
  border-bottom: 1px solid #d4e0e6; }
  @media only screen and (max-width: 575px) {
    .mi-header {
      padding-bottom: 0.25rem; } }
  @media only screen and (max-width: 480px) {
    .mi-header {
      padding-bottom: 0.25rem; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .mi-header {
      padding-top: 0.25rem; } }
  @media only screen and (max-width: 767px) {
    .mi-header {
      padding-top: 0.25rem; } }
  .mi-header-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 2.66667rem; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .mi-header-inner {
        padding-left: 1rem;
        padding-right: 0.5rem; } }
    @media only screen and (max-width: 767px) {
      .mi-header-inner {
        padding-left: 1rem;
        padding-right: 0.5rem; } }
  .mi-header-image {
    padding: 20px 0;
    display: block;
    transition: all 0.7s ease-in-out 0s;
    width: 14rem;
    text-align: center; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .mi-header-image {
        width: 12rem; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .mi-header-image {
        width: 8rem; } }
    @media only screen and (max-width: 767px) {
      .mi-header-image {
        width: 4rem; } }
  .mi-header-menu-container {
    display: flex; }
  .mi-header-menu {
    display: flex;
    margin-bottom: 0rem; }
    .mi-header-menu-button {
      align-self: center;
      font-size: 1.25rem;
      border: none;
      border-radius: 3px;
      display: block;
      background: linear-gradient(to right, #037fff 50%, #cce5ff 50%);
      background-size: 200% 100%;
      background-position: right bottom;
      transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
      transform-origin: center right;
      color: black;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-top: 1rem;
      padding-bottom: 1rem; }
      .mi-header-menu-button a {
        color: black;
        text-decoration: none; }
      .mi-header-menu-button-text {
        text-align: center;
        line-height: 30px;
        display: block; }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .mi-header-menu-button-text {
            font-size: 0.7rem; } }
        @media only screen and (max-width: 767px) {
          .mi-header-menu-button-text {
            font-size: 0.7rem; } }
        @media only screen and (max-width: 575px) {
          .mi-header-menu-button-text {
            font-size: 0.6rem; } }
        @media only screen and (max-width: 480px) {
          .mi-header-menu-button-text {
            font-size: 0.6rem; } }
      .mi-header-menu-button-icon {
        font-size: 1.25rem;
        margin-left: 1rem; }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .mi-header-menu-button-icon {
            font-size: 0.7rem;
            margin-left: 0.5rem; } }
        @media only screen and (max-width: 767px) {
          .mi-header-menu-button-icon {
            font-size: 0.7rem;
            margin-left: 0.5rem; } }
    .mi-header-menu-button:hover {
      background-position: left bottom; }
    .mi-header-menu-button:hover {
      transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
      color: white; }
    .mi-header-menu-button:hover i {
      transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
      color: white; }
    .mi-header-menu-button-link {
      text-decoration: none;
      transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
      color: white; }
    .mi-header-menu li {
      list-style: none;
      text-align: center;
      display: flex; }
      .mi-header-menu li:not(:last-child) {
        margin-bottom: 1px; }
      .mi-header-menu li a {
        text-transform: uppercase;
        font-size: 0.94rem;
        letter-spacing: 1px;
        display: block;
        padding: 1.33333rem;
        color: #000000;
        transition: all 0.4s ease-out;
        font-weight: 600;
        position: relative;
        overflow: hidden;
        z-index: 1;
        text-decoration: none;
        /*&:hover {
          color: $color-theme;

          &::before {
            width: 100%;
            visibility: visible;
          }
        }*/ }
        @media only screen and (max-width: 575px) {
          .mi-header-menu li a {
            font-size: 0.6rem;
            padding-left: 1rem;
            padding-right: 1rem; } }
        @media only screen and (max-width: 480px) {
          .mi-header-menu li a {
            font-size: 0.6rem;
            padding-left: 1rem;
            padding-right: 1rem; } }
        .mi-header-menu li a:hover::after {
          transform: scaleX(1); }
        .mi-header-menu li a::after {
          background-color: #037fff;
          content: "";
          display: block;
          height: 2px;
          transform-origin: left;
          transform: scaleX(0);
          transition: transform 0.75s cubic-bezier(0.19, 1, 0.22, 1);
          width: 100%;
          position: absolute;
          bottom: 1.25em;
          left: 0; }
        .mi-header-menu li a::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 100%;
          height: 100%;
          width: 0;
          /*background: rgba($color-theme, 0.2);*/
          visibility: hidden;
          transition: all 0.4s ease-out;
          z-index: -1; }
        .mi-header-menu li a.active {
          color: #037fff; }
          .mi-header-menu li a.active::before {
            /*background: $color-theme;*/
            width: 100%;
            visibility: visible; }
  .mi-header-copyright {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    font-size: 1rem;
    padding: 15px 0;
    border-top: 1px solid #d4e0e6; }
    .mi-header-copyright a {
      color: #8d9a9e;
      transition: all 0.4s ease-out; }
      .mi-header-copyright a:hover {
        color: #037fff; }
  .mi-header.is-visible {
    transform: translateX(0); }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .mi-header {
      width: 100%; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .mi-header {
      width: 100%; }
      .mi-header-toggler {
        display: flex; }
      .mi-header-image a {
        height: 150px;
        width: 150px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .mi-header {
      width: 100%; }
      .mi-header-toggler {
        display: flex; }
      .mi-header-image a {
        height: 150px;
        width: 150px; } }
  @media only screen and (max-width: 767px) {
    .mi-header {
      width: 100%; }
      .mi-header-toggler {
        display: flex; }
      .mi-header-image a {
        height: 150px;
        width: 150px; } }

.outer {
  margin: 50px; }

.text {
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  color: black;
  transition: all 0.6s ease-out;
  display: block; }

/*
 * Title 
 */
.mi-sectiontitle {
  margin-bottom: 60px;
  position: relative;
  z-index: 1; }
  .mi-sectiontitle span {
    position: absolute;
    left: 0;
    top: 100%;
    font-size: 6rem;
    line-height: 1;
    font-weight: 900;
    color: rgba(25, 29, 43, 0.44);
    display: inline-block;
    text-transform: uppercase;
    z-index: -1;
    transform: translateY(-40%);
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    white-space: nowrap; }
  .mi-sectiontitle h2 {
    font-weight: 700;
    position: relative;
    padding-bottom: 15px;
    text-transform: uppercase; }
    .mi-sectiontitle h2::before {
      content: "";
      position: absolute;
      left: 0;
      top: auto;
      bottom: 0;
      height: 5px;
      width: 100px;
      background: rgba(3, 127, 255, 0.3);
      border-radius: 100px; }
    .mi-sectiontitle h2::after {
      content: "";
      position: absolute;
      left: 0;
      top: auto;
      bottom: 0;
      height: 5px;
      width: 35px;
      background: #037fff;
      border-radius: 100px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .mi-sectiontitle span {
      font-size: 4.5rem; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .mi-sectiontitle span {
      font-size: 4.5rem; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .mi-sectiontitle {
      margin-bottom: 50px; }
      .mi-sectiontitle span {
        font-size: 4.2rem; } }
  @media only screen and (max-width: 767px) {
    .mi-sectiontitle {
      margin-bottom: 40px; }
      .mi-sectiontitle span {
        font-size: 3.8rem; } }
  @media only screen and (max-width: 575px) {
    .mi-sectiontitle span {
      font-size: 3.2rem; } }

.mi-smalltitle {
  display: flex;
  align-items: center; }
  .mi-smalltitle h4 {
    font-weight: 700; }
  .mi-smalltitle-icon {
    vertical-align: middle;
    margin-right: 10px;
    font-size: 1.71rem;
    line-height: 2.43rem;
    color: #8d9a9e; }
  @media only screen and (max-width: 767px) {
    .mi-smalltitle-icon {
      font-size: 1.51rem;
      line-height: 2.13rem; } }

/*
 * Progress Bar
 */
.mi-progress-title {
  font-weight: 600;
  margin-bottom: 0; }

.mi-progress-inner {
  display: flex;
  align-items: center; }

.mi-progress-percentage {
  flex: 0 0 60px; }

.mi-progress-container {
  background: #d4e0e6;
  display: block;
  height: 6px;
  width: 100%;
  position: relative; }

.mi-progress-active {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #037fff;
  width: 0;
  transition: all 1s cubic-bezier(0.01, 0.57, 0.68, 1.05) 0s; }

/**
 * Service
 */
.mi-service {
  border: 1px solid #d4e0e6;
  border-top: 5px solid #d4e0e6;
  padding: 30px;
  background: white;
  transition: all 0.4s ease-out; }
  .mi-service-icon {
    margin-bottom: 20px;
    display: inline-block;
    color: #037fff;
    font-size: 2.5rem; }
  .mi-service h5 {
    font-weight: 600;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px; }
    .mi-service h5::before {
      content: "";
      position: absolute;
      left: 0;
      top: auto;
      bottom: 0;
      height: 2px;
      width: 50px;
      background: #d4e0e6; }
  .mi-service p {
    margin-bottom: 0; }
  .mi-service:hover {
    border-top-color: #037fff; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .mi-service {
      padding: 20px; } }
  @media only screen and (max-width: 575px) {
    .mi-service {
      padding: 20px; } }

/**
 * Form Styles
 */
.mi-form-field {
  margin-top: 30px;
  position: relative; }
  .mi-form-field label {
    position: absolute;
    left: 15px;
    top: -13px;
    background: #ffffff;
    transition: all 0.4s ease-out;
    pointer-events: none;
    padding: 0 10px;
    font-size: 0.94rem;
    color: #000; }
  .mi-form-field input {
    /*border-bottom: 1px solid $color-border;*/
    border-bottom: 1px solid #8d9a9e;
    color: #000; }
  .mi-form-field input,
  .mi-form-field textarea {
    /*border: 1px solid $color-border;*/
    font-size: 1rem;
    border-bottom: 1px solid #8d9a9e;
    color: #000; }

.notfound {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%; }
  .notfound-inner {
    background: white;
    padding: 100px 50px;
    width: 100%; }
  .notfound h1 {
    font-size: 12.5rem;
    line-height: 1;
    font-weight: 700;
    color: #037fff; }
  .notfound h3 {
    margin-top: -10px; }
  .notfound .mi-button {
    margin-top: 20px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .notfound h1 {
      font-size: 10.5rem; } }
  @media only screen and (max-width: 767px) {
    .notfound-inner {
      padding: 75px 15px; }
    .notfound h1 {
      margin-top: -15px;
      font-size: 8.5rem; } }
  @media only screen and (max-width: 767px) {
    .notfound-inner {
      padding: 65px 15px; }
    .notfound h1 {
      font-size: 7.5rem; } }

.mi-footer {
  /* Weird - take a look at*/ }
  .mi-footer-container {
    background-color: #000;
    height: 300px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex: 1 0 auto; }
  .mi-footer-container-list {
    align-items: center;
    display: flex;
    width: 100%;
    flex: 1 0 auto; }
    .mi-footer-container-list-col {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      align-items: center; }
  .mi-footer-container-list ul li a {
    transition: all 0.3s ease 0s;
    color: #777777;
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    font-size: 0.9rem; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .mi-footer-container-list ul li a {
        font-size: 0.7rem; } }
    @media only screen and (max-width: 767px) {
      .mi-footer-container-list ul li a {
        font-size: 0.7rem; } }
  .mi-footer-container-list ul li {
    color: #777777;
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    font-size: 0.9rem; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .mi-footer-container-list ul li {
        font-size: 0.7rem; } }
    @media only screen and (max-width: 767px) {
      .mi-footer-container-list ul li {
        font-size: 0.7rem; } }
  .mi-footer-container-list h4 {
    color: "white";
    margin-bottom: 20px;
    font-size: 1.25rem;
    width: 40%; }
  .mi-footer-container-list ul {
    width: 40%;
    list-style: none; }
  .mi-footer-container-list ul li a:hover {
    color: #fff; }

.mi-child-hero {
  /*position: absolute;*/
  width: 100%;
  /*top: 50%;
    left: 0;*/
  z-index: 20;
  transform: translateY(-450%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 5rem;
  flex: 1 0 auto;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .mi-child-hero-title {
    text-align: center; }
  .mi-child-hero-subtitle {
    font-weight: 700;
    text-align: center;
    text-shadow: 0px 3px 6px #000;
    color: #fff;
    font-size: 2rem; }

.mi-child-img-filter > img {
  object-fit: cover;
  height: 35rem;
  width: 100%;
  -webkit-filter: brightness(75%);
          filter: brightness(75%); }

.AnyClassForContainer {
  position: fixed;
  right: -100px;
  bottom: 150px;
  transition: right 0.5s;
  cursor: pointer;
  background-color: white;
  font-size: 20px;
  padding: 10px; }

.AnyClassForTransition {
  right: 20px; }

html,
body {
  height: 100%; }

a {
  color: white; }

.long {
  width: 100%;
  background-color: #010e19; }

.scroll {
  opacity: 0.3;
  background-color: #037fff;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 10px;
  right: 20px;
  border-radius: 5px;
  border: none;
  z-index: 1000; }
  .scroll:hover {
    opacity: 1; }

.arrow-up {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0; }

/*
 * Home Styles
 */
.mi-home-area {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  flex: 1 0 auto; }

.mi-home-hero {
  width: 100%;
  z-index: 20;
  transform: translateY(-25rem);
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: space-between;
  height: 5rem;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .mi-home-hero {
      transform: translateY(-27rem); } }
  @media only screen and (max-width: 767px) {
    .mi-home-hero {
      transform: translateY(-27rem); } }
  .mi-home-hero-title {
    font-size: 4rem;
    color: white;
    font-weight: 700;
    text-align: center;
    text-shadow: 0px 3px 6px #000; }
  .mi-home-hero-subtitle {
    font-size: 1.5rem;
    color: white;
    font-weight: 700;
    text-align: center;
    text-shadow: 0px 3px 6px #000; }

.mi-home-vid-filter {
  -webkit-filter: brightness(50%);
          filter: brightness(50%); }

.mi-home-vid {
  height: calc(100vh - 100px);
  position: relative;
  object-fit: cover;
  width: 100%; }

.mi-home-cards {
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;
  margin: 0 auto; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .mi-home-cards {
      flex-direction: column;
      width: 100%; } }
  @media only screen and (max-width: 767px) {
    .mi-home-cards {
      flex-direction: column;
      width: 100%; } }

.mi-home-cards > .mi-home-card {
  width: 25%;
  height: 20rem;
  z-index: 99;
  padding: 2.5rem;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.27);
  font-size: 0.9rem; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .mi-home-cards > .mi-home-card {
      width: 30%; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .mi-home-cards > .mi-home-card {
      width: 30%;
      font-size: 0.8rem; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .mi-home-cards > .mi-home-card {
      width: 100%;
      height: 18rem;
      margin-bottom: 2rem; } }
  @media only screen and (max-width: 767px) {
    .mi-home-cards > .mi-home-card {
      width: 100%;
      height: 18rem;
      margin-bottom: 2rem; } }

.mi-home-cards > .mi-home-top-card {
  transform: translateY(-120px); }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .mi-home-cards > .mi-home-top-card {
      transform: translateY(-120px); } }
  @media only screen and (max-width: 767px) {
    .mi-home-cards > .mi-home-top-card {
      transform: translateY(-120px); } }

.mi-home-cards > .mi-home-bottom-card {
  height: 10rem; }

.mi-home-lightbulb {
  color: #fff;
  transition: all 0.2s ease-in;
  margin-right: "1rem"; }

.mi-home-lightbulb:hover {
  transition: all 0.2s ease-in;
  color: #fff;
  -webkit-filter: drop-shadow(0 0 15px #fdec6e) drop-shadow(0 0 30px #fdec6e) drop-shadow(0 0 60px #fdec6e);
          filter: drop-shadow(0 0 15px #fdec6e) drop-shadow(0 0 30px #fdec6e) drop-shadow(0 0 60px #fdec6e); }

.mi-home-svg {
  background: radial-gradient(ellipse farthest-side at 100% 100%, #4a87f5 20%, #2e70e6 50%, #0352cc 110%);
  position: absolute;
  height: 20rem;
  width: 100%;
  z-index: -10; }

.mi-home-section {
  color: #000;
  height: 25rem;
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center; }
  .mi-home-section-flex {
    flex-direction: row;
    display: flex;
    flex: 1 0 auto; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .mi-home-section-flex {
        flex-direction: column; } }
    @media only screen and (max-width: 767px) {
      .mi-home-section-flex {
        flex-direction: column; } }
  @media only screen and (min-width: 1600px) and (max-width: 1919px) {
    .mi-home-section {
      height: 30rem; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .mi-home-section {
      height: 30rem; } }
  @media only screen and (max-width: 767px) {
    .mi-home-section {
      height: 30rem; } }
  .mi-home-section-container {
    width: 50%; }
    @media only screen and (min-width: 1600px) and (max-width: 1919px) {
      .mi-home-section-container {
        width: 70%; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .mi-home-section-container {
        width: 90%; } }
    @media only screen and (max-width: 767px) {
      .mi-home-section-container {
        width: 90%; } }
  .mi-home-section-chart {
    margin-top: 4rem;
    margin-bottom: 4rem; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .mi-home-section-chart {
        flex-direction: column; } }
    @media only screen and (max-width: 767px) {
      .mi-home-section-chart {
        flex-direction: column; } }
  .mi-home-section-chart-body {
    display: flex;
    flex: 1 0 auto;
    color: #000;
    font-size: 1.5rem;
    width: 30%;
    margin-left: 1rem;
    margin-right: 1rem; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .mi-home-section-chart-body {
        width: 90%;
        margin-bottom: 2rem;
        margin-top: 2rem;
        text-align: center; } }
    @media only screen and (max-width: 767px) {
      .mi-home-section-chart-body {
        width: 90%;
        margin-bottom: 2rem;
        margin-top: 2rem;
        text-align: center; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .mi-home-section-chart-chart {
      width: 90%; } }
  @media only screen and (max-width: 767px) {
    .mi-home-section-chart-chart {
      width: 90%; } }
  .mi-home-section-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    padding-top: 0.1rem;
    z-index: 3;
    text-align: center; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .mi-home-section-title {
        font-size: 2.3rem; } }
    @media only screen and (max-width: 767px) {
      .mi-home-section-title {
        font-size: 2.3rem; } }
  .mi-home-section-body {
    z-index: 3;
    display: flex;
    flex: 1 0 auto; }
  .mi-home-section-art .mi-home-section-icon {
    transition: all 1s ease-out; }
  .mi-home-section-art:hover .mi-home-section-icon {
    fill: gold;
    z-index: 3; }
  .mi-home-section-icon {
    font-size: 12rem; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .mi-home-section-icon {
        font-size: 6rem; } }
    @media only screen and (max-width: 767px) {
      .mi-home-section-icon {
        font-size: 6rem; } }
  .mi-home-section-art {
    background-image: linear-gradient(to left, #f5f5f5, #f5f5f5);
    margin-top: 2rem;
    margin-bottom: 2rem;
    transition: all 1s ease-out;
    z-index: 1;
    position: relative;
    padding-top: 5rem;
    padding-bottom: 5rem; }
  .mi-home-section-art:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to left, #fff0f5, #f5fff0, #f0faff, #fff5f0);
    transition: opacity 1.5s ease-out;
    z-index: 2;
    opacity: 0; }
  .mi-home-section-art:hover:after {
    opacity: 1; }
  .mi-home-section-art .mi-home-section-rainbow {
    z-index: 3;
    position: relative; }
  .mi-home-section-art:hover .mi-home-section-rainbow {
    background-image: linear-gradient(to left, violet, indigo, blue, green, gold, orange, crimson, red);
    -webkit-background-clip: text;
            background-clip: text;
    z-index: 3;
    position: relative;
    -webkit-animation: becomerainbow 1.5s;
            animation: becomerainbow 1.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }

@-webkit-keyframes becomerainbow {
  0% { }
  100% {
    color: transparent; } }

@keyframes becomerainbow {
  0% { }
  100% {
    color: transparent; } }

#circlewrap {
  text-align: center; }

#circlewrap > div {
  display: inline-block;
  position: relative; }

#circlewrap > div div {
  position: absolute; }

#circlewrap .ball1,
#circlewrap .ball3,
#circlewrap .ball2 {
  left: 15px;
  top: 20px;
  width: 10px;
  height: 10px; }

/*#circlewrap .green {
  width: 60px;
  height: 70px;
  background-color: green;
}*/
#circlewrap .ball1 {
  z-index: -5;
  border-radius: 50px;
  background-color: #3ddc84;
  -webkit-animation: yellow 5s;
          animation: yellow 5s;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

#circlewrap .ball2 {
  z-index: -5;
  border-radius: 50px;
  background-color: #3ddc84;
  -webkit-animation: orange 5s;
          animation: orange 5s;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

#circlewrap .ball3 {
  z-index: -5;
  border-radius: 50px;
  background-color: #3ddc84;
  -webkit-animation: blue 5s;
          animation: blue 5s;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

@-webkit-keyframes yellow {
  from {
    transform: rotate(0deg) translate(75px) rotate(0deg); }
  to {
    transform: rotate(360deg) translate(0px) rotate(-360deg); } }

@keyframes yellow {
  from {
    transform: rotate(0deg) translate(75px) rotate(0deg); }
  to {
    transform: rotate(360deg) translate(0px) rotate(-360deg); } }

@-webkit-keyframes blue {
  from {
    transform: rotate(120deg) translate(75px) rotate(-120deg); }
  to {
    transform: rotate(480deg) translate(0px) rotate(-480deg); } }

@keyframes blue {
  from {
    transform: rotate(120deg) translate(75px) rotate(-120deg); }
  to {
    transform: rotate(480deg) translate(0px) rotate(-480deg); } }

@-webkit-keyframes orange {
  from {
    transform: rotate(240deg) translate(75px) rotate(-240deg); }
  to {
    transform: rotate(600deg) translate(0px) rotate(-600deg); } }

@keyframes orange {
  from {
    transform: rotate(240deg) translate(75px) rotate(-240deg); }
  to {
    transform: rotate(600deg) translate(0px) rotate(-600deg); } }

.mi-home-card-web svg {
  height: 3rem;
  width: 3rem;
  transition: all 1.5s ease-out; }

.mi-home-card-web:hover svg {
  height: 5rem;
  width: 5rem; }

/*
 * About Styles
 */
.mi-about-image {
  position: relative; }
  .mi-about-image::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 65%;
    width: 15px;
    background: rgba(3, 127, 255, 0.6); }
  .mi-about-image::after {
    content: "";
    position: absolute;
    left: auto;
    right: 0;
    top: auto;
    bottom: 0;
    height: 65%;
    width: 15px;
    background: rgba(3, 127, 255, 0.6); }
  .mi-about-image img {
    width: 100%; }
  .mi-about-image-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100px;
    width: 100px;
    margin-left: -50px;
    margin-top: -50px;
    color: #ffffff;
    display: inline-block;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    transition: all 0.4s ease-out 0s;
    pointer-events: none; }
    .mi-about-image-icon svg {
      height: 100%;
      width: 100%; }
  .mi-about-image:hover .mi-about-image-icon {
    visibility: visible;
    opacity: 0.5;
    transform: scale(1); }

/**
 * Contact
 */
.mi-contact-form {
  /*&-checkboxes > div {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }*/ }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .mi-contact-form-checkboxes, .mi-contact-form-radiobuttons {
      flex-direction: column;
      align-items: center; } }
  @media only screen and (max-width: 767px) {
    .mi-contact-form-checkboxes, .mi-contact-form-radiobuttons {
      flex-direction: column;
      align-items: center; } }
  .mi-contact-form-checkboxes label {
    margin-left: 5%;
    color: #000;
    font-size: 0.9rem; }
  .mi-contact-form-title {
    color: #000; }

.mi-contact-form .p-checkbox .p-checkbox-box {
  border: 1px #037fff solid; }

.mi-contact-form .p-field-radiobutton label {
  color: #000; }

.p-field-checkbox {
  justify-content: center; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .p-field-checkbox {
      justify-content: flex-start; } }
  @media only screen and (max-width: 767px) {
    .p-field-checkbox {
      justify-content: flex-start; } }

.p-field-radiobutton {
  justify-content: center; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .p-field-radiobutton {
      justify-content: flex-start; } }
  @media only screen and (max-width: 767px) {
    .p-field-radiobutton {
      justify-content: flex-start; } }

