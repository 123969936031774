.AnyClassForContainer {
  position: fixed;
  right: -100px;
  bottom: 150px;
  transition: right 0.5s;
  cursor: pointer;
  background-color: white;
  font-size: 20px;
  padding: 10px;
}

.AnyClassForTransition {
  right: 20px;
}

html,
body {
  height: 100%;
}

a {
  color: white;
}

.long {
  width: 100%;
  background-color: #010e19;
}

.scroll {
  opacity: 0.3;
  background-color: $color-theme;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 10px;
  right: 20px;
  border-radius: 5px;
  border: none;
  z-index: 1000;

  &:hover {
    opacity: 1;
  }
}

.arrow-up {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
