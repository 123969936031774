/*
 * Home Styles
 */

.mi-home {
  &-area {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    position: relative;
    z-index: 1;
    flex: 1 0 auto;
  }
  &-hero {
    width: 100%;
    z-index: 20;
    -ms-transform: translateY(-25rem);
    -moz-transform: translateY(-25rem);
    -o-transform: translateY(-25rem);
    -webkit-transform: translateY(-25rem);
    transform: translateY(-25rem);

    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: space-between;
    height: 5rem;
    user-select: none;

    @media #{$layout-tablet} {
      transform: translateY(-27rem);
    }

    @media #{$layout-mobile-lg} {
      transform: translateY(-27rem);
    }

    &-title {
      font-size: 4rem;
      color: white;
      font-weight: 700;
      text-align: center;
      text-shadow: 0px 3px 6px #000;
    }
    &-subtitle {
      font-size: 1.5rem;
      color: white;
      font-weight: 700;
      text-align: center;
      text-shadow: 0px 3px 6px #000;
    }
  }
  &-vid-filter {
    filter: brightness(50%);
  }
  &-vid {
    height: calc(100vh - 100px);
    position: relative;
    object-fit: cover;
    width: 100%;
  }
  &-cards {
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
    justify-content: space-around;
    width: 80%;
    margin: 0 auto;

    @media #{$layout-tablet} {
      flex-direction: column;
      width: 100%;
    }

    @media #{$layout-mobile-lg} {
      flex-direction: column;
      width: 100%;
    }
  }
  &-cards > .mi-home-card {
    width: 25%;
    height: 20rem;

    z-index: 99;
    padding: 2.5rem;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.27);
    font-size: 0.9rem;
    @media #{$layout-laptop} {
      width: 30%;
    }

    @media #{$layout-notebook} {
      width: 30%;
      font-size: 0.8rem;
    }

    @media #{$layout-tablet} {
      width: 100%;
      height: 18rem;
      margin-bottom: 2rem;
    }

    @media #{$layout-mobile-lg} {
      width: 100%;
      height: 18rem;
      margin-bottom: 2rem;
    }
  }
  &-cards > .mi-home-top-card {
    -webkit-transform: translateY(-120px);
    transform: translateY(-120px);
    @media #{$layout-tablet} {
      transform: translateY(-120px);
    }

    @media #{$layout-mobile-lg} {
      transform: translateY(-120px);
    }
  }
  &-cards > .mi-home-bottom-card {
    height: 10rem;
  }

  &-lightbulb {
    color: #fff;
    transition: all 0.2s ease-in;
    margin-right: "1rem";
  }
  &-lightbulb:hover {
    transition: all 0.2s ease-in;
    color: #fff;
    filter: drop-shadow(0 0 15px #fdec6e) drop-shadow(0 0 30px #fdec6e)
      drop-shadow(0 0 60px #fdec6e);
  }
  &-svg {
    background: radial-gradient(
      ellipse farthest-side at 100% 100%,
      #4a87f5 20%,
      #2e70e6 50%,
      #0352cc 110%
    );
    position: absolute;
    height: 20rem;
    width: 100%;
    z-index: -10;
  }
  &-section {
    color: #000;
    height: 25rem;
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    justify-content: center;

    &-flex {
      flex-direction: row;
      display: flex;
      flex: 1 0 auto;
      @media #{$layout-tablet} {
        flex-direction: column;
      }

      @media #{$layout-mobile-lg} {
        flex-direction: column;
      }
    }

    @media #{$layout-desktop-xs} {
      height: 30rem;
    }

    @media #{$layout-tablet} {
      height: 30rem;
    }

    @media #{$layout-mobile-lg} {
      height: 30rem;
    }

    &-container {
      width: 50%;
      @media #{$layout-desktop-xs} {
        width: 70%;
      }
      @media #{$layout-tablet} {
        width: 90%;
      }

      @media #{$layout-mobile-lg} {
        width: 90%;
      }
    }
    &-chart {
      margin-top: 4rem;
      margin-bottom: 4rem;
      @media #{$layout-tablet} {
        flex-direction: column;
      }

      @media #{$layout-mobile-lg} {
        flex-direction: column;
      }
    }
    &-chart-body {
      display: flex;
      flex: 1 0 auto;
      color: #000;
      font-size: 1.5rem;
      width: 30%;
      margin-left: 1rem;
      margin-right: 1rem;
      @media #{$layout-tablet} {
        width: 90%;
        margin-bottom: 2rem;
        margin-top: 2rem;
        text-align: center;
      }

      @media #{$layout-mobile-lg} {
        width: 90%;
        margin-bottom: 2rem;
        margin-top: 2rem;
        text-align: center;
      }
    }
    &-chart-chart {
      @media #{$layout-tablet} {
        width: 90%;
      }

      @media #{$layout-mobile-lg} {
        width: 90%;
      }
    }

    &-title {
      font-size: 2.5rem;
      font-weight: bold;
      margin-bottom: 2rem;
      padding-top: 0.1rem;
      z-index: 3;
      text-align: center;

      @media #{$layout-tablet} {
        font-size: 2.3rem;
      }

      @media #{$layout-mobile-lg} {
        font-size: 2.3rem;
      }
    }
    &-body {
      z-index: 3;
      display: flex;
      flex: 1 0 auto;
    }
    &-art &-icon {
      transition: all 1s ease-out;
    }
    &-art:hover &-icon {
      fill: gold;
      z-index: 3;
    }
    &-icon {
      font-size: 12rem;
      @media #{$layout-tablet} {
        font-size: 6rem;
      }

      @media #{$layout-mobile-lg} {
        font-size: 6rem;
      }
    }
    &-art {
      background-image: linear-gradient(to left, #f5f5f5, #f5f5f5);
      margin-top: 2rem;
      margin-bottom: 2rem;
      transition: all 1s ease-out;
      z-index: 1;
      position: relative;
      padding-top: 5rem;
      padding-bottom: 5rem;
    }

    &-art:after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        to left,
        #fff0f5,
        #f5fff0,
        #f0faff,
        #fff5f0
      );
      transition: opacity 1.5s ease-out;
      z-index: 2;
      opacity: 0;
    }
    &-art:hover:after {
      opacity: 1;
    }

    &-art &-rainbow {
      z-index: 3;
      position: relative;
    }
    &-art:hover &-rainbow {
      background-image: linear-gradient(
        to left,
        violet,
        indigo,
        blue,
        green,
        gold,
        orange,
        crimson,
        red
      );
      background-clip: text;
      z-index: 3;
      position: relative;
      animation: becomerainbow 1.5s;
      animation-fill-mode: forwards;
    }
  }

  @keyframes becomerainbow {
    0% {
    }
    100% {
      color: transparent;
    }
  }
}
#circlewrap {
  text-align: center;
}
#circlewrap > div {
  display: inline-block;
  position: relative;
}
#circlewrap > div div {
  position: absolute;
}

#circlewrap .ball1,
#circlewrap .ball3,
#circlewrap .ball2 {
  left: 15px;
  top: 20px;
  width: 10px;
  height: 10px;
}
/*#circlewrap .green {
  width: 60px;
  height: 70px;
  background-color: green;
}*/
#circlewrap .ball1 {
  z-index: -5;
  border-radius: 50px;
  background-color: #3ddc84;
  animation: yellow 5s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}
#circlewrap .ball2 {
  z-index: -5;
  border-radius: 50px;
  background-color: #3ddc84;
  animation: orange 5s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}
#circlewrap .ball3 {
  z-index: -5;
  border-radius: 50px;
  background-color: #3ddc84;
  animation: blue 5s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

@keyframes yellow {
  from {
    transform: rotate(0deg) translate(75px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translate(0px) rotate(-360deg);
  }
}
@keyframes blue {
  from {
    transform: rotate(120deg) translate(75px) rotate(-120deg);
  }
  to {
    transform: rotate(480deg) translate(0px) rotate(-480deg);
  }
}
@keyframes orange {
  from {
    transform: rotate(240deg) translate(75px) rotate(-240deg);
  }
  to {
    transform: rotate(600deg) translate(0px) rotate(-600deg);
  }
}

.mi-home-card-web svg {
  height: 3rem;
  width: 3rem;
  transition: all 1.5s ease-out;
}
.mi-home-card-web:hover svg {
  height: 5rem;
  width: 5rem;
}
