/*
 * About Styles
 */

.mi-about {
  &-image {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 65%;
      width: 15px;
      background: rgba($color-theme, 0.6);
    }

    &::after {
      content: "";
      position: absolute;
      left: auto;
      right: 0;
      top: auto;
      bottom: 0;
      height: 65%;
      width: 15px;
      background: rgba($color-theme, 0.6);
    }

    img {
      width: 100%;
    }

    &-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      height: 100px;
      width: 100px;
      margin-left: -50px;
      margin-top: -50px;
      color: #ffffff;
      display: inline-block;
      visibility: hidden;
      opacity: 0;
      transform: scale(0);
      transition: all 0.4s ease-out 0s;
      pointer-events: none;

      svg {
        height: 100%;
        width: 100%;
      }
    }

    &:hover &-icon {
      visibility: visible;
      opacity: 0.5;
      transform: scale(1);
    }
  }
}
