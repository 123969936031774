/**
 * Header Styles
 */

.mi-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  z-index: 5000;
  border-bottom: 1px solid $color-border;
  @media #{$layout-mobile-sm} {
    padding-bottom: 0.25rem;
  }
  @media #{$layout-mobile-xs} {
    padding-bottom: 0.25rem;
  }

  @media #{$layout-tablet} {
    padding-top: 0.25rem;
  }

  @media #{$layout-mobile-lg} {
    padding-top: 0.25rem;
  }

  &-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 2.66667rem;
    @media #{$layout-tablet} {
      padding-left: 1rem;
      padding-right: 0.5rem;
    }

    @media #{$layout-mobile-lg} {
      padding-left: 1rem;
      padding-right: 0.5rem;
    }
  }

  &-image {
    padding: 20px 0;
    display: block;
    transition: all 0.7s ease-in-out 0s;
    width: 14rem;

    text-align: center;
    @media #{$layout-laptop} {
      width: 12rem;
    }

    @media #{$layout-tablet} {
      width: 8rem;
    }

    @media #{$layout-mobile-lg} {
      width: 4rem;
    }
  }

  &-menu-container {
    display: flex;
  }

  &-menu {
    display: flex;
    margin-bottom: 0rem;

    &-button {
      align-self: center;
      font-size: 1.25rem;
      border: none;

      border-radius: 3px;
      display: block;

      background: linear-gradient(to right, #037fff 50%, #cce5ff 50%);
      background-size: 200% 100%;
      background-position: right bottom;
      transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
      transform-origin: center right;
      color: black;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-top: 1rem;
      padding-bottom: 1rem;

      a {
        color: black;
        text-decoration: none;
      }

      &-text {
        text-align: center;
        line-height: 30px;
        display: block;
        @media #{$layout-tablet} {
          font-size: 0.7rem;
        }

        @media #{$layout-mobile-lg} {
          font-size: 0.7rem;
        }
        @media #{$layout-mobile-sm} {
          font-size: 0.6rem;
        }
        @media #{$layout-mobile-xs} {
          font-size: 0.6rem;
        }
      }
      &-icon {
        font-size: 1.25rem;
        margin-left: 1rem;
        @media #{$layout-tablet} {
          font-size: 0.7rem;
          margin-left: 0.5rem;
        }

        @media #{$layout-mobile-lg} {
          font-size: 0.7rem;
          margin-left: 0.5rem;
        }
      }
    }

    &-button:hover {
      background-position: left bottom;
    }
    &-button:hover {
      transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
      color: white;
    }
    &-button:hover i {
      transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
      color: white;
    }
    &-button-link {
      text-decoration: none;
      transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
      color: white;
    }

    li {
      list-style: none;
      text-align: center;
      display: flex;

      &:not(:last-child) {
        margin-bottom: 1px;
      }

      a {
        text-transform: uppercase;
        font-size: 0.94rem;
        letter-spacing: 1px;
        display: block;
        padding: 1.33333rem;
        color: $color-secondary;
        transition: $transition;
        font-weight: 600;
        position: relative;
        overflow: hidden;
        z-index: 1;
        text-decoration: none;

        @media #{$layout-mobile-sm} {
          font-size: 0.6rem;
          padding-left: 1rem;
          padding-right: 1rem;
        }
        @media #{$layout-mobile-xs} {
          font-size: 0.6rem;
          padding-left: 1rem;
          padding-right: 1rem;
        }

        &:hover::after {
          transform: scaleX(1);
        }

        &::after {
          background-color: $color-theme;
          content: "";
          display: block;
          height: 2px;
          transform-origin: left;
          transform: scaleX(0);
          transition: transform 0.75s cubic-bezier(0.19, 1, 0.22, 1);
          width: 100%;
          position: absolute;
          bottom: 1.25em;
          left: 0;
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 100%;
          height: 100%;
          width: 0;
          /*background: rgba($color-theme, 0.2);*/
          visibility: hidden;
          transition: $transition;
          z-index: -1;
        }

        /*&:hover {
          color: $color-theme;

          &::before {
            width: 100%;
            visibility: visible;
          }
        }*/

        &.active {
          color: $color-theme;

          &::before {
            /*background: $color-theme;*/
            width: 100%;
            visibility: visible;
          }
        }
      }
    }
  }

  &-copyright {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    font-size: 1rem;
    padding: 15px 0;
    border-top: 1px solid $color-border;

    a {
      color: $color-body;
      transition: $transition;

      &:hover {
        color: $color-theme;
      }
    }
  }

  &.is-visible {
    transform: translateX(0);
  }

  @media #{$layout-laptop} {
    width: 100%;
  }

  @media #{$layout-notebook} {
    width: 100%;

    &-toggler {
      display: flex;
    }

    &-image {
      a {
        height: 150px;
        width: 150px;
      }
    }
  }

  @media #{$layout-tablet} {
    width: 100%;

    &-toggler {
      display: flex;
    }

    &-image {
      a {
        height: 150px;
        width: 150px;
      }
    }
  }

  @media #{$layout-mobile-lg} {
    width: 100%;

    &-toggler {
      display: flex;
    }

    &-image {
      a {
        height: 150px;
        width: 150px;
      }
    }
  }
}

.outer {
  margin: 50px;
}

.text {
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  color: black;
  transition: all 0.6s ease-out;
  display: block;
}
