/**
 * Contact
 */

.mi-contact-form {
  /*&-checkboxes > div {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }*/
  &-checkboxes,
  &-radiobuttons {
    @media #{$layout-tablet} {
      flex-direction: column;
      align-items: center;
    }

    @media #{$layout-mobile-lg} {
      flex-direction: column;
      align-items: center;
    }
  }
  &-checkboxes label {
    margin-left: 5%;
    color: #000;
    font-size: 0.9rem;
  }
  &-title {
    color: #000;
  }
}
.mi-contact-form .p-checkbox .p-checkbox-box {
  border: 1px #037fff solid;
}
.mi-contact-form .p-field-radiobutton label {
  color: #000;
}
.p-field-checkbox {
  justify-content: center;
  @media #{$layout-tablet} {
    justify-content: flex-start;
  }

  @media #{$layout-mobile-lg} {
    justify-content: flex-start;
  }
}
.p-field-radiobutton {
  justify-content: center;
  @media #{$layout-tablet} {
    justify-content: flex-start;
  }

  @media #{$layout-mobile-lg} {
    justify-content: flex-start;
  }
}
