.mi-child {
  &-hero {
    /*position: absolute;*/
    width: 100%;
    /*top: 50%;
    left: 0;*/
    z-index: 20;
    transform: translateY(-450%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 5rem;
    flex: 1 0 auto;
    user-select: none;
    &-title {
      text-align: center;
    }
    &-subtitle {
      font-weight: 700;
      text-align: center;
      text-shadow: 0px 3px 6px #000;
      color: $color-title;
      font-size: 2rem;
    }
  }

  &-img-filter > img {
    object-fit: cover;
    height: 35rem;
    width: 100%;
    filter: brightness(75%);
  }
}
